import { firstBy } from "thenby";

import milestoneTypes from '../../shared/milestone-types'

import BaseModule from '@/store/base-module'



export interface Collab {
	_id: string;
	creator: string;
	priceNegotiated: number;
	status: string;
	statusColor: string;
	rating: number;
	milestones: {
		[milestoneId: string]: {
			status: string;
			statusColor: string;
			rating: number;
			priceNegotiated: number;
		};
	};
}

export default BaseModule({
	name: 'collabs',
	state: {
		milestoneTypes,
		sortMethodName: { name: 'Rating and status' }
	},
	mutations: {
		SET_SORT_METHOD_NAME(state, sortMethodName) {
			state.sortMethodName = sortMethodName
		},
	},
	actions: {
		addCreator(context, data) {
			return context.dispatch('create', {
				creator: data.creator,
				project: data.project
			})
		},
		removeCreator(context, data) {
			let existingCollab;

			if (data.collab) {
				existingCollab = context.getters.byId(data.collab);
			} else if (data.creator && data.project) {
				existingCollab = context.getters.all.find(collab => (
					collab.creator === data.creator &&
					collab.project == data.project
				))
			}

			if (existingCollab) {
				return context.dispatch('delete', existingCollab._id).then(() => {
					return context.dispatch('app/setStatusMessage', `Creator removed creator from project`, { root: true })
				})
			}
		},
		toggleCreator(context, data) {
			const existingCollab = context.getters.all.find(collab => (
				collab.creator === data.creator &&
				collab.project == data.project
			))
			if (existingCollab) {
				return context.dispatch('removeCreator', data);
			} else {
				return context.dispatch('addCreator', data);
			}
		},
		addMilestone(context, data) {
			try {
				const milestones = { ...context.getters.byId(data.collabId).milestones };
				milestones[data.type] = {
					milestoneTypeId: data.type,
					user: context.rootGetters['user/me']._id,
					complete: true,
					metadata: data.data || {}
				}
				return context.dispatch('update', {
					_id: data.collabId,
					milestones
				})
			} catch (e) {
				return e
			}
		},
		removeMilestone(context, data) {
			try {
				const milestones = { ...context.getters.byId(data.collabId).milestones };
				milestones[data.type].complete = false;
				return context.dispatch('update', {
					_id: data.collabId,
					milestones
				})
			} catch (e) {
				return e
			}
		},
		toggleMilestone(context, data) {
			try {
				const milestones = { ...context.getters.byId(data.collabId).milestones };
				const milestone = milestones[data.type];
				const complete = !!(milestone && milestone.createdAt && milestone.complete !== false)
				if (milestone && complete) {
					return context.dispatch('removeMilestone', data)
				}
				return context.dispatch('addMilestone', data)
			} catch (e) {
				return e
			}
		}
	},
	getters: {
		all: (state) => {
			return Object.values(state.documents).sort(
				firstBy((a: Collab, b: Collab) => {
					return Number(a.rating || Infinity) - Number(b.rating || Infinity);
				}).thenBy((a: Collab, b: Collab) => {
					return Number(!!b.status) - Number(!!a.status);
				}).thenBy((a: Collab, b: Collab) => {
					return Object.keys(b.milestones || {}).length - Object.keys(a.milestones || {}).length
				}).thenBy((a: Collab, b: Collab) => {
					return Number(!!b.statusColor) - Number(!!a.statusColor);
				})
			)
		},
		find: (state, getters) => query => {
			return getters.all.find(collab => (
				collab.creator === query.creator &&
				collab.project == query.project
			)) || false;
		},
		exists: (state, getters) => query => {
			return !!getters.all.find(collab => (
				collab.creator === query.creator &&
				collab.project == query.project
			))
		},
		forSelectedBrand: (state, getters, rootState, rootGetters) => {
			return rootGetters['account/selected']._id ? getters.forBrand(rootGetters['account/selected']._id) : []
		},
		forBrand: (state, getters) => brandAccountId => {
			return getters.all.filter(collab => collab.brand === brandAccountId)
		},
		forAccountAndCreator: (state, getters) => creatorId => {
			return getters.forSelectedAccount.filter(collab => collab.creator == creatorId)
		},
		byProject: (state, getters) => {
			const projectIds = [...new Set(getters.all.map(collab => collab.project))] as Array<string>;
			const projects = {};
			projectIds.forEach(projectId => {
				projects[projectId] = getters.all.filter(collab => collab.project === projectId)
			})
			return projects;
		},
		getMilestoneTypeById: state => milestoneTypeId => {
			return state.milestoneTypes.find(milestoneType => milestoneType.id == milestoneTypeId)
		}
	},
	async beforeSave(context, doc) {
		if (!doc._id) {
			// This is a new document

			if (!doc.brand) {
				doc.brand = context.rootGetters['account/selected']._id
				if (!doc.brand) {
					throw new Error('A collab must have a brand')
				}
			}
			if (!doc.user) {
				doc.user = context.rootGetters['user/me']._id
				if (!doc.user) {
					throw new Error('A collab must have a user')
				}
			}
		}
		if (doc.priceNegotiated) {
			doc.priceNegotiated = String(doc.priceNegotiated).replace(/\D/g, '')
		}
		if (doc.pricePaid) {
			doc.pricePaid = String(doc.pricePaid).replace(/\D/g, '')
		}
	},
	async onCreate(context) {
		return context.dispatch('app/setStatusMessage', `Creator added to project`, { root: true })
	}
})
