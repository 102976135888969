import axios from 'axios';

export default {
  name: 'app-config',
  namespaced: true,
  state: {
    contentTopics: [],
    contentTypes: [],
	verticals: [],
	adminOnlyContentTopics: []
  },
  mutations: {
    SET(state, config){
      Object.keys(config).forEach(key => {
          state[key] = config[key];
      });
    }
  },
  actions: {
    async load(context){
      try{
        const config = await axios.get('/api/app-config');
        context.commit('SET', config.data[0]);
      } catch(e){
        console.error(e);
      }
    },
	async update(context, config){
	  try{
		console.log({
			id: context.state._id,
			config
		})
		const response = await axios.put('/api/app-config/'+ context.state._id, config);
		context.commit('SET', response.data);
	  } catch(e){
		console.error(e);
	  }
	}
  },
  getters: {
    get: (state) => key => {
      return state[key] || null
    },
    contentTopics: (state, getters, rootState, rootGetters) => {
		let contentTopics = state.contentTopics || [];
		if(rootGetters['user/isAdmin']){
			contentTopics = contentTopics.concat(state.adminOnlyContentTopics || [])
		}
		return contentTopics.sort()
    },
    contentTypes: state => {
      return (state.contentTypes || []).sort();
    },
    verticals: state => {
      return (state.verticals || []).sort();
    }
  }
}