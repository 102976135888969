<template>
  <div :class="{active: isRunning, 'v-tour': true}" v-if="name">
    <slot
      :current-step="currentStep"
      :steps="steps"
      :previous-step="previousStep"
      :next-step="nextStep"
      :stop="stop"
      :skip="skip"
      :finish="finish"
      :is-first="isFirst"
      :is-last="isLast"
      :labels="customOptions.labels"
      :enabled-buttons="customOptions.enabledButtons"
      :highlight="customOptions.highlight"
      :debug="customOptions.debug"
    >
      <!--Default slot {{ currentStep }}-->
      <TourStep
        v-if="steps[currentStep]"
        :step="steps[currentStep]"
        :key="currentStep"
        :previous-step="previousStep"
        :next-step="nextStep"
        :stop="stop"
        :skip="skip"
        :finish="finish"
        :is-first="isFirst"
        :is-last="isLast"
        :labels="customOptions.labels"
        :enabled-buttons="customOptions.enabledButtons"
        :highlight="customOptions.highlight"
        :stop-on-fail="customOptions.stopOnTargetNotFound"
        :debug="customOptions.debug"
        @targetNotFound="$emit('targetNotFound', $event)"
      >
        <!--<div v-if="index === 2" slot="actions">
          <a @click="nextStep">Next step</a>
        </div>-->
      </TourStep>
    </slot>
  </div>
</template>

<script lang="ts">

import TourStep from "@/components/molecules/TourStep.vue";
import { DEFAULT_CALLBACKS, DEFAULT_OPTIONS, KEYS } from '@/store/tour-constants'


export default {
  name: 'Tour',
  components: {
    TourStep
  },
  props: {
    options: {
      type: Object,
      default: () => { return DEFAULT_OPTIONS }
    },
    callbacks: {
      type: Object,
      default: () => { return DEFAULT_CALLBACKS }
    }
  },
  data () {
    return {
      currentStep: -1,
      initialDisplayDelay: 2500,
      onRouteTimeout: null
    }
  },
  mounted () {
    this.$tours[this.name] = this
  },
  beforeDestroy () {
    // Remove the keyup listener if it has been defined
    if (this.customOptions.useKeyboardNavigation) {
      window.removeEventListener('keyup', this.handleKeyup)
    }
  },
  computed: {
    name(){
      return this.$route.meta?.tour?.name;
    },
    steps(){
      return this.$root.tours[this.name]
    },
    initialStep(){
      return this.steps.findIndex(step => step.target === this.$route.meta?.tour?.target)
    },
    // Allow us to define custom options and merge them with the default options.
    // Since options is a computed property, it is reactive and can be updated during runtime.
    customOptions () {
      return {
        ...DEFAULT_OPTIONS,
        ...this.options
      }
    },
    customCallbacks () {
      return {
        ...DEFAULT_CALLBACKS,
        ...this.callbacks
      }
    },
    // Return true if the tour is active, which means that there's a VStep displayed
    isRunning () {
      return this.currentStep > -1 && this.currentStep < this.numberOfSteps
    },
    isFirst () {
      return this.currentStep === 0
    },
    isLast () {
      return this.currentStep === this.steps.length - 1
    },
    numberOfSteps () {
      return this.steps.length
    },
    step () {
      return this.steps[this.currentStep]
    },
    isComplete(){
      return this.$store.getters['app/getTourStatus'][this.name] === 'complete';
    }
  },
  watch: {
    isComplete: {
      handler (isComplete) {
        // console.log('isComplete changed', this.steps);
        if(!isComplete && this.steps){
          this.start();
        }
      }
    },
    $route: {
      handler () {
        if(!this.isComplete && this.steps && !this.isRunning){
          clearTimeout(this.onRouteTimeout);
          this.onRouteTimeout = setTimeout(() => {
            this.start();
          }, this.initialDisplayDelay)
        }
      },
      immediate: true
    }
  },
  methods: {
    async start (startStep) {
      // Register keyup listeners for this tour
      if (this.customOptions.useKeyboardNavigation) {
        window.addEventListener('keyup', this.handleKeyup)
      }

      // Wait for the DOM to be loaded, then start the tour
      startStep = typeof startStep !== 'undefined' ? parseInt(startStep, 10) : this.initialStep || 0;
      const step = this.steps[startStep]

      const process = () => new Promise((resolve) => {
        setTimeout(() => {
          this.customCallbacks.onStart()
          this.currentStep = startStep
          resolve()
        }, this.customOptions.startTimeout)
      })

      if (typeof step.before !== 'undefined') {
        try {
          await step.before('start').catch(e => {
            console.warn(e)
          });
        } catch (e) {
          return Promise.reject(e)
        }
      }
      await process()

      return Promise.resolve()
    },
    async previousStep () {
      const futureStep = this.currentStep - 1

      const process = () => new Promise((resolve) => {
        this.customCallbacks.onPreviousStep(this.currentStep)
        this.currentStep = futureStep
        resolve()
      })

      if (futureStep > -1) {
        const step = this.steps[futureStep]
        if (typeof step.before !== 'undefined') {
          try {
            await step.before('previous')
          } catch (e) {
            return Promise.reject(e)
          }
        }
        await process()
      }

      return Promise.resolve()
    },
    async nextStep () {
      const futureStep = this.currentStep + 1

      const process = () => new Promise((resolve) => {
        this.customCallbacks.onNextStep(this.currentStep)
        this.currentStep = futureStep
        resolve()
      })

      if (futureStep < this.numberOfSteps && this.currentStep !== -1) {
        const step = this.steps[futureStep]
        if (typeof step.before !== 'undefined') {
          try {
            await step.before('next')
          } catch (e) {
            return Promise.reject(e)
          }
        }
        await process()
      }

      return Promise.resolve()
    },
    stop () {
      this.customCallbacks.onStop()
      document.body.classList.remove('v-tour--active')
      this.currentStep = -1;
      this.$store.commit('app/SET_TOUR_STATUS', {
        tour: this.name,
        status: 'complete'
      });
    },
    skip () {
      this.customCallbacks.onSkip()
      this.stop()
    },
    finish () {
      this.customCallbacks.onFinish()
      this.stop()
    },

    handleKeyup (e) {
      if (this.customOptions.debug) {
        console.log('[Vue Tour] A keyup event occured:', e)
      }
      switch (e.keyCode) {
        case KEYS.ARROW_RIGHT:
          this.isKeyEnabled('arrowRight') && this.nextStep()
          break
        case KEYS.ARROW_LEFT:
          this.isKeyEnabled('arrowLeft') && this.previousStep()
          break
        case KEYS.ESCAPE:
          this.isKeyEnabled('escape') && this.stop()
          break
      }
    },
    isKeyEnabled (key) {
      const { enabledNavigationKeys } = this.customOptions
      return enabledNavigationKeys[key] || true
    }
  }
}
</script>

<style lang="less">
  @import "../../less/variables.less";
  @import "../../less/mixins.less";
  body.v-tour--active {
  }

  .v-tour {
    pointer-events: auto;
    &.active{
      opacity: 0;
      z-index: 2;
      animation: fadein 500ms ease-in-out forwards;
    }
  }

  .v-tour__target--highlighted {
    border:2px solid @green !important;
    -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, .4);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, .4);
    pointer-events: auto;
    z-index: 9999
  }

  .v-tour__target--relative {
    position: relative
  }
  .tour-highlight{
    .tourHighlightWithGlow();
  }
</style>