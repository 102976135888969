export default [
    {
        id: 'add',
        name:'Add to project',
        nameComplete:'Added to Project',
        editable: false
    },
    {
        id:'contact',
        name:'Reach out',
        nameComplete:'Reached out',
        editable: true,
        component: 'MilestoneCollabContact',
        componentComplete: 'MilestoneCollabContact'
    },
    {
        id:'explore',
        name:'Hear back',
        nameComplete:'Contacted & Connected',
        editable: true
    },
    {
        id: 'deal',
        name:'Set pricing / deal',
        nameComplete:'Pricing / Deal Set',
        editable: true,
        component: 'MilestoneCollabPricing',
        componentComplete: 'MilestoneCollabPricing'
    },
    {
        id: 'agreement',
        name: 'Sign agreement',
        nameComplete: 'Agreement Signed',
        editable: true,
        component: 'MilestoneCollabAgreement',
        componentComplete: 'MilestoneCollabAgreement'
    },
    {
        id:'w9',
        name:'Collect W9',
        nameComplete:'W9 on file',
        editable: true,
        component: 'MilestoneCollabW9',
        componentComplete: 'MilestoneCollabW9'
    },
    {
        id:'brief-send',
        name:'Send brief',
        nameComplete:'Brief Sent',
        editable: true,
    },
    {
        id:'product-send',
        name:'Ship product',
        nameComplete:'Product Shipped',
        editable: true,
        component: 'MilestoneCollabShipment',
        componentComplete: 'MilestoneCollabShipment'
    },
    {
        id:'product-receive',
        name:'Confirm receipt of product',
        nameComplete:'Product Received',
        editable: true,
    },
    {
        id:'delivery',
        name:'Receive Deliverables',
        nameComplete:'Deliverables Received',
        editable: true,
        component: {
            name: 'MilestoneCollabChecklist',
            props: {
                field: 'deliverables',
                itemName: 'Deliverable'
            }
        },
        componentComplete: {
            name: 'MilestoneCollabChecklist',
            props: {
                field: 'deliverables',
                itemName: 'Deliverable'
            }
        }
    },
    {
        id:'pay',
        name:'Pay Creator',
        nameComplete:'Paid in full',
        editable: true,
        component: 'MilestoneCollabPayment',
        componentComplete: 'MilestoneCollabPayment'
    },
    {
        id:'post',
        name:'Track Performance',
        nameComplete:'Track Performance',
        editable: true,
        component: 'MilestoneCollabMedia',
        componentComplete: 'MilestoneCollabMedia'
    },
    {
        id:'custom-checklist',
        name:'Custom Checklist',
        nameComplete:'Custom Checklist',
        editable: true,
        component: {
            name: 'MilestoneCollabChecklist',
            props: {
                field: 'customChecklist',
                itemName: 'Task'
            }
        },
        componentComplete: {
            name: 'MilestoneCollabChecklist',
            props: {
                field: 'customChecklist',
                itemName: 'Task'
            }
        }
    },
]